.initialise {
  margin: 0 auto;
  width: 500px;
  position: relative;
  top: 100px;
}

.message {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.25;
  text-align: center;
  padding-bottom: 20px;
  width: 400px;
  margin: 0 auto;
}

@import "src/scss/variables";

.form {
  width: 367px;
  padding-bottom: 30px;

  button + button {
    margin-left: 14px;
  }

  &ControlLabel {
    justify-content: space-between;
    margin: 0;
  }
}

.heading {
  text-align: right;
  padding: 16px;
  font-size: 14px;
  background-color: $secondary;
  color: white;
  font-weight: bold;
}

.table {
  margin: 20px 0 30px 0;
}

.checkbox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 0 25px;
}

.checkbox:not(:last-child) {
  border-bottom: 1px solid $grey1;
}

.checkboxList {
  border: 1px solid $grey1;

  label {
    & > span {
      padding-right: 0;
    }
  }
}

  .headerFeature {
    display:flex;
  }

  .headerCenter {
    justify-content: center; 
  }
